import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {BonReceptionDTO} from '../../dtos/bon-reception-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {UnitesDeMesuresService} from './unites-de-mesures.service';
import {BonCfDTO} from '../../dtos/boncfs-dto';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCalendarSupplier} from '../../suppliers/form-field-calendar-supplier';
import {ReceptionStatutDTO} from '../../dtos/reception-statut-dto';
import {catchError} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {BonReceptionLigneDTO} from '../../dtos/bon-reception-ligne-dto';
import {saveAs as fs_saveAs} from 'file-saver';
import {startCase as _startCase} from 'lodash';
import {HttpService} from "../technique/http.service";


export const URL_POST_INIT_BON_RECEPTION = `dolrest/gestion-reception/init/bon-reception`;
export const URL_POST_INIT_BON_RECEPTION_LIGNE = `dolrest/gestion-reception/init/bon-reception-ligne`;
export const URL_POST_PRINT_PDF_BON_RECEPTION = `dolrest/gestion-reception/print/pdf/bon-reception`;
export const URL_POST_PRINT_PDF_BONS_RECEPTION = `dolrest/gestion-reception/print/pdf/bons-reception`;
export const URL_GET_ARTICLES = `dolrest/gestion-reception/articles`;
export const URL_POST_UPDATE_MONTANTS_ET_FRAIS_BON_RECEPTION = 'dolrest/gestion-reception/update/montants-et-frais-de-port-bon-reception'
export const URL_POST_UPDATE_MONTANTS_BON_RECEPTION_LIGNE = 'dolrest/gestion-reception/update/montants-bon-reception-ligne'
export const URL_POST_UPDATE_ARTICLES = `dolrest/gestion-reception/update/bon-reception-lignes`;
export const URL_POST_DELETE_BON_RECEPTION = `dolrest/gestion-reception/delete/bon-reception`;
export const URL_POST_UPDATE_BON_RECEPTION = `dolrest/gestion-reception/franco-de-port-cout`;
export const URL_GET_BON_RECEPTION = `dolrest/gestion-reception`;

@Injectable({
  providedIn: 'root'
})
export class BonReceptionService extends GenericHandler<BonReceptionDTO> {

  private subjectBonReceptionGridDxView = new Subject();
  bonReceptionGridDxView$ = this.subjectBonReceptionGridDxView.asObservable();


  constructor(utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,
              title: Title,
              private httpSvc: HttpService
  ) {
    super(utils, auth2Svc, router, http, title);

  }


  announceBonReceptionGridDxView = (bonReception: BonReceptionDTO) => {
    this.subjectBonReceptionGridDxView.next(bonReception);
  };

  createEmptyDTO = (): BonReceptionDTO => new BonReceptionDTO();

  initDTO = (numeroBonReception: string, bonCf: BonCfDTO, receptionStatut: ReceptionStatutDTO): BonReceptionDTO => {

    const bonReception = new BonReceptionDTO();

    bonReception.numeroBonReception = numeroBonReception;
    bonReception.dateLivraison = new Date();
    bonReception.numeroCommandeBonCf = bonCf.numeroBcf;
    bonReception.idBonCf = bonCf.id;
    bonReception.receptionStatut = new ReceptionStatutDTO();
    bonReception.receptionStatut = receptionStatut;
    bonReception.libelleUniteDeProduction = bonCf.uniteDeProductionLibelle;

    return bonReception;

  };

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => '';

  getEditObjectLabel = (data: BonReceptionDTO): string => '';

  getEntityName = (): string => 'bonReception';

  getFields = (dto: BonReceptionDTO): FormFieldBaseSupplier<any>[] => {

    if (this.utils.isNullOrEmpty(dto)) {
      dto = this.createEmptyDTO();
    }


    let formFields: FormFieldBaseSupplier<any>[] = [

      new FormFieldTextboxSupplier({
        key: 'numeroBonReception',
        label: 'Bon de réception no.',
        help: 'Numéro du bon de réception. Non modifiable.',
        value: dto.numeroBonReception,
        readonly: true,
        width: 200,
        maxLength: 50,
        required: false,
        order: 1
      }),

      new FormFieldTextboxSupplier({
        key: 'numeroBonLivraison',
        label: 'Bon de livraison no.',
        help: 'Numéro du bon de livraison.',
        value: dto.numeroBonLivraison,
        readonly: false,
        width: 200,
        maxLength: 50,
        required: false,
        order: 2
      }),

      new FormFieldCalendarSupplier({
        key: 'dateLivraison',
        label: 'Date de livraison',
        value: dto.dateLivraison,
        required: true,
        showTime: true,
        dateFormat: 'dd/mm/yy',
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'livreur',
        label: 'Livreur',
        help: 'Nom ou Prénom du livreur',
        width: 200,
        value: dto.livreur,
        required: false,
        order: 4
      }),

      new FormFieldTextboxSupplier({
        key: 'immatriculation',
        label: 'Immatriculation',
        help: 'Immatriculation du véhicule',
        value: dto.immatriculation,
        required: false,
        width: 200,
        order: 5
      }),

      new FormFieldTextboxSupplier({
        key: 'temperatureCamion',
        label: 'Température du camion',
        type: 'number',
        help: 'Température en degrés celsius',
        suffix: '° celsius',
        width: 40,
        value: dto.temperatureCamion,
        required: false,
        order: 6
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 9
      }),

      new FormFieldTextboxSupplier({
        key: 'idBonCf',
        type: 'hidden',
        value: dto.idBonCf,
        order: 10
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);
  };

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => 'GESTION DES BONS DE RÉCEPTION';

  /**
   * initialiser un bon de réception
   * @param bonReception
   */
  init = (bonReception: BonReceptionDTO) => {
    return this.http.post(URL_POST_INIT_BON_RECEPTION, bonReception).pipe(
      catchError(error => this.utils.handleError(error)));
  };

  /**
   * Afficher les articles liés à un bon de réception
   * @param bonReception
   */
  getArticles = (bonReception: BonReceptionDTO) => {

    return this.http.get(URL_GET_ARTICLES + '/' + bonReception.id).pipe(
      catchError(error => this.utils.handleError(error)));
  };

  /**
   * Modifier les montants et les frais de port d'un bon de réception
   * @param bonReception
   */
  updateMontantsEtFraisBonReception = (bonReception: BonReceptionDTO) => {
    let formData = new FormData();
    formData.set('bonReception', JSON.stringify(bonReception));

    return this.http.post(URL_POST_UPDATE_MONTANTS_ET_FRAIS_BON_RECEPTION, formData)
      .pipe(catchError(error => this.utils.handleError(error)));
  };

  /**
   * modifier un bon de réception
   * @param bonReception
   */
  updateBonReception = (bonReception: BonReceptionDTO) => {
    let formData = new FormData();
    formData.set('bonReception', JSON.stringify(bonReception));
    return this.http.post(URL_POST_UPDATE_BON_RECEPTION, formData).pipe(
      catchError(error => this.utils.handleError(error)));
  };


  /**
   * Modifier les montants d'un bon de réception ligne
   * @param bonReceptionLigne
   */
  updateMontantsBonReceptionLigne = (bonReceptionLigne: BonReceptionLigneDTO) => {
    let formData = new FormData();
    formData.set('bonReceptionLigne', JSON.stringify(bonReceptionLigne));

    return this.http.post(URL_POST_UPDATE_MONTANTS_BON_RECEPTION_LIGNE, formData)
      .pipe(catchError(error => this.utils.handleError(error)));
  };

  /**
   *
   * @param lignesBonReception
   * @param updateMode 1= Mettre à jour, 2 = clore la reception, elle reste incomplete, 3 = clore la reception
   */
  updateBonReceptionLignes = (bonReceptionId: number, lignesBonReception: BonReceptionLigneDTO[], updateMode: number) => {
    const url = `${URL_POST_UPDATE_ARTICLES}/${bonReceptionId}?updateMode=${updateMode}`;

    const body = lignesBonReception.map(ligne => {
      return {
        id: ligne.id,
        lotArticle: {
          id: ligne.idLotArticle,
          lotArticle: ligne.numeroLotInterne,
          lotArticleFournisseur: ligne.numeroLotFabriquant
        },
        quantiteRecue: ligne.quantiteRecue,
        prixUnitaireFacture: ligne.prixUnitaireFacture,
        prixUnitairePrev: ligne.prixUnitairePrev,
        totalFacture: ligne.totalFacture,
        totalFactureTTC: ligne.totalFactureTTC,
        idUdpZds: ligne.idUdpZds,
        idZds: ligne.idZds,
        bonReceptionLigneAppellationList: ligne.bonReceptionLigneAppellationList,
        bonReceptionLigneLitigeList: ligne.bonReceptionLigneLitigeList,
        bonReceptionLigneMotifNonConfoList: ligne.bonReceptionLigneMotifNonConfoList,
        idOrigine: ligne.idOrigine,
        temperatureCelsius: ligne.temperatureCelsius,
        remarque: ligne.remarque,
        dlc: ligne.dlc,
        naissance: ligne.naissance,
        elevage: ligne.elevage,
        abattage: ligne.abattage
      }
    });

    return this.httpSvc.post(url, body);
  };

  printPDFBonReception = (bonReception: BonReceptionDTO) => {

    return this.http.post(URL_POST_PRINT_PDF_BON_RECEPTION, {}, {
      params: new HttpParams().set('idBonReception', bonReception.id + ''),
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  printPDFBonsReceptions = (idsOrders: number[]) => {
    return this.http.post(URL_POST_PRINT_PDF_BONS_RECEPTION, {}, {
      params: new HttpParams().set('idsOrders', idsOrders && idsOrders.length ? idsOrders.join(',') : ''),
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  };

  getPDFBonReception = (bonReception: BonReceptionDTO) => {

    this.printPDFBonReception(bonReception).subscribe(response => {

      let blob = new Blob([response], {type: 'application/pdf'});
      fs_saveAs(blob, 'bon-reception.pdf');

    });

  };


  /**
   * Supprimer un bon de réception<br>
   * Si la réception a déjà des stocks réservés, on interdit la suppression
   * @param bonReception
   */
  delete = (bonReception: BonReceptionDTO) => {

    const fd = new FormData();
    fd.set('idBonReception', bonReception.id + '');

    return this.http.post(URL_POST_DELETE_BON_RECEPTION, fd).pipe(
      catchError(error => this.utils.handleError(error, true)))

  };

  calculateTva = (lignesBonReception: BonReceptionLigneDTO[], bonReception: BonReceptionDTO): number => {

    let montantTva = 0;

    lignesBonReception.forEach((lbr: BonReceptionLigneDTO) => {
      montantTva += (lbr.totalFacture * lbr.tauxTva) / 100;
    });

    montantTva += (bonReception.francoDePortCout * bonReception.francoDePortTva) / 100;

    return parseFloat(montantTva.toFixed(4));
  };

  findBonReceptionById(id: number) {
    return this.httpSvc.get(URL_GET_BON_RECEPTION + "/" + id).pipe(
      catchError(error => this.utils.handleError(error)));
  }
}
