// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.0-rc-10173(c790a74c77)-C19/04/2024-16:05:17-B19/04/2024-16:09:11' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.0-rc-10173(c790a74c77)-C19/04/2024-16:05:17-B19/04/2024-16:09:11",
  branch: "master",
  latestTag: "6.0-rc",
  revCount: "10173",
  shortHash: "c790a74c77",
  longHash: "c790a74c77025667a9ad42268189096dd11c931a",
  dateCommit: "19/04/2024-16:05:17",
  dateBuild: "19/04/2024-16:09:11",
  buildType: "Ansible",
  } ;
